<template>
  <div class="order-detail-modal-container">
    <sweet-modal ref="modal"
                 overlay-theme="dark"
                 width="700px"
                 hideCloseButton
                 enable-mobile-fullscreen>
      <div class="modal-container">
        <div class="modal-header">
          <div class="body2-medium">구매내역</div>
          <img src="/static/icon/fi_close_outline.svg" class="svg-sub3 unselect" @click="$refs.modal.close()">
        </div>
        <div class="modal-content">
          <div class="flex-align">
            <img :src="order.order_product.product.img" :alt="order.order_product.product.name" class="img-product">
            <div>
              <div class="size-15 weight-500">{{ order.order_product.product.name }}</div>
              <div v-if="order.order_product.option" style="margin-top:2px"
                   class="body5 sub3">{{ order.order_product.option.name }}</div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="h8 main margin-bottom-16">결제 정보</div>
          <div class="grid-account">
            <div class="body4-medium sub2">총 결제금액</div>
            <div class="body4-bold main">{{ order.total_price|currency }}</div>
          </div>
          <div class="grid-account">
            <div class="body4-medium sub2">결제 수단</div>
            <div class="body4 main" v-if="order.bill">카드결제 / {{ order.bill.card_name }} ({{ order.bill.num }})</div>
          </div>
          <div class="grid-account">
            <div class="body4-medium sub2">결제일시</div>
            <div class="body4 main">{{ datesFormat(order.pay_day, 'date_time_3') }}</div>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue'
  export default {
    name: "OrderDetailModal",
    components: {
      SweetModal
    },
    props: {
      order: {
        type: Object
      }
    },
    data() {
      return {

      }
    },
    methods: {
      open() {
        this.$refs.modal.open();
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .modal-header
    background-color $gray2
    padding 16px 24px
    display flex
    justify-content space-between
    align-items center
  .modal-container
    width 700px
    max-width 100vw
    min-height 366px
    max-height 366px

  .modal-content
    padding 20px

  .divider
    background-color $gray1
    width 100%
    height 1px
    margin 24px 0

  .img-product
    width 80px
    height 80px
    object-fit cover
    border-radius 8px
    margin-right 12px

  .grid-account
    display grid
    grid-template-columns 100px 1fr
    grid-gap 20px
    margin-bottom 10px
</style>
<style lang="stylus">
  .order-detail-modal-container
    .sweet-modal.is-alert .sweet-content
      padding 0 !important
      text-align left

  @media screen and (max-width: 600px)
    .order-detail-modal-container
      .sweet-modal.is-mobile-fullscreen.is-visible
        border-radius 0 !important
</style>
